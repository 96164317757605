import React from 'react'

export const About = () => {
  return (
    <>
    <br />
    <div className="container text-secondary">
        <br />
        <hr className="divider"/>
        <h1 className="text-center text-success fw-bold" style={{fontSize: "xx-large"}}>About Our Company</h1>
        <hr className="divider"/>
        <h6>About Us</h6>                
        <p>We are a leading manufacturer of high-quality, single-use plastic food packaging products, dedicated to providing innovative, reliable, and sustainable solutions for the food service and food packaging industries. With over 14 years of experience, we have built a reputation for producing packaging that ensures the safety, freshness, and convenience of food products, all while meeting the specific needs of our diverse clientele.</p>
        <h6>Our Commitment to Quality and Innovation</h6>
        <p>Quality is at the heart of everything we do. Our products undergo rigorous quality control checks at every stage of production to ensure they meet international standards for safety and performance. Utilizing the latest manufacturing technologies, we focus on creating packaging that offers both functionality and durability while maintaining an attractive, user-friendly design.</p>
        <p>Innovation is also a key aspect of our business. We invest in research and development to create new, more efficient packaging solutions that meet the growing demand for both functionality and sustainability. Our team works closely with customers to develop packaging that not only serves its purpose but also aligns with market trends and consumer preferences.</p>
        <h6>Sustainability and Environmental Responsibility</h6>
        <p>While we specialize in single-use plastic packaging, we understand the growing concern for environmental impact. We are committed to reducing our ecological footprint through responsible production practices and ongoing efforts to make our products more sustainable. We actively research and implement eco-friendly alternatives, such as biodegradable plastics and recyclable materials, in our production processes.</p>
        <p>Additionally, we are working to develop products that can be reused or repurposed, reducing the need for disposables. Our mission is to balance the need for efficient food packaging with our responsibility to the planet.</p>
        <h6>Our Vision</h6>
        <p>Our vision is to be the preferred partner for businesses in the food service and packaging industries, providing packaging solutions that are both practical and environmentally responsible. We aim to lead by example, setting new standards in the industry for quality, innovation, and sustainability.</p>
        <p>At <img src={require('./icons/AHR-Logo-50-x-50_bgGreen.png')} alt="" style={{width: "25px", height: "25px"}} />, we believe that great packaging can make a significant difference in food presentation, safety, and convenience. We are committed to continuing our legacy of excellence and helping our clients thrive in an ever-evolving market.</p>    
    </div>
    <br/>
    </>
  )
}
