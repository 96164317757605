import React from 'react'
import fbFollow from './icons/find-us-on-facebook-icon.svg'
import wbFollow from './icons/whatsapp-follow.svg'

export const Footer = () => {
  
    let footerdiv = {
        display: "flex",
        flexDirection: "row", 
        justifyContent: "space-between"
        
    }

    let footerimg = {
        width:"100%",
        maxWidth: "100px",
        height: "35px"
    }
   
    return (
        <>
        
            <footer className="container-fluid bg-success text-light">

                <div style={footerdiv}>
                    <div>
                        <a href="https://www.facebook.com/ahrindustries/"><img src={fbFollow} style={footerimg} alt="" loading="lazy"/></a>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <p className="h6">Copyright &copy; 2011 - 2025.</p>
                    </div>
                    <div >
                        <a href="https://whatsapp.com/channel/0029Va8Ft6E7IUYPs6063o40"><img src={wbFollow} style={footerimg} alt="" loading="lazy"/></a>
                    </div>

                </div>

            </footer>
        </>
  )
}
