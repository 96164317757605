import React from 'react'

export const Product_EPS = () => {
  return (
    <>
    <br />
    <div className="container text-secondary">
        <hr className="divider" />
        <div className="d-flex align-items-center justify-content-center">
            <img src={require("./icons/AHR-Logo-50-x-50_bgGreen.png")} alt=""/>
            <h6 className="text-center text-success fw-bold" style={{fontSize: "x-large"}}>Foam Cups</h6>           
        </div>
        <hr className="divider" />
        <p>Our foam cups are designed to provide superior insulation, keeping beverages hot or cold longer. Available in a range of sizes, they are the ideal choice for cafes, restaurants, and convenience stores.</p>
        <p>Maintain beverages at their optimal temperature longer with <img src={require("./icons/AHR-Logo-50-x-50_bgGreen.png")} alt="" style={{width: "25px", height: "25px"}} /> insulated foam cups. Not only do foam cups keep beverages at their proper serving temperature on the inside, they keep hands comfortable on the outside. Hot or cold, insulated foam delivers drinks the way they were meant to be. Available different sizes, <img src={require("./icons/AHR-Logo-50-x-50_bgGreen.png")} alt="" style={{width: "25px", height: "25px"}} /> insulated foam cups are ideal for coffee, cappuccino, tea, hot chocolate, hot cider, juice, and soft drinks.</p>
        <div className="container-fluid">      
            <div className="d-flex justify-content-evenly flex-sm-row flex-column" >
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/A06-R.png")} className="card-img-top" alt="" style={{width:"30%"}}/>
                    </div>
                    <div className="card-body">  
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft:"5px"}}>FC-A06-R</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft:"5px"}}>EPS Foam Cup 6 Oz (R-RIM)</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft:"5px"}}>25 PCS x 40 Sleeves (1000 Cups) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft:"5px"}}>Expandable Polystyrene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Color</td>
                                <td className="text-secondary" style={{paddingLeft:"5px"}}>White</td>
                            </tr>
                            </tbody>
                        </table>                
                    </div>
                </div>
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/A06-I.png")} className="card-img-top" alt="" style={{width:"30%"}} />
                    </div>
                    <div className="card-body">
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>FC-A06-I</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>EPS Foam Cup 6 Oz (I-RIM)</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>25 PCS x 40 Sleeves (1000 Cups) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Expandable Polystyrene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Color</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>White</td>
                            </tr>
                            </tbody>
                        </table>        
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-evenly flex-sm-row flex-column" >
                
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/A08-I.png")} className="card-img-top" alt="" style={{width:"30%"}} />
                    </div>
                    <div className="card-body">
                        
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>FC-A08-I</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>EPS Foam Cup 8 Oz (I-RIM)</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>25 PCS x 40 Sleeves (1000 Cups) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Expandable Polystyrene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Color</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>White</td>
                            </tr>
                            </tbody>
                        </table>        
                    </div>
                </div>
                <div className="card shadow-lg mb-5 bg-white rounded">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/A12-I.png")} className="card-img-top" alt="" style={{width:"30%"}} />
                    </div>
                    <div className="card-body">  
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>FC-A12-I</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>EPS Foam Cup 12 Oz (I-RIM)</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>25 PCS x 20 Sleeves (500 Cups) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Expandable Polystyrene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Color</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>White</td>
                            </tr>
                            </tbody>
                        </table>                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br></br>
    </>
  )
}
