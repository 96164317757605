import React from 'react'

export const Product_PP = () => {
  return (
    <>
    <br />
    <div className="container text-secondary">
        <hr className="divider" />
        <div className="d-flex align-items-center justify-content-center">
            <img src={require("./icons/AHR-Logo-50-x-50_bgGreen.png")} alt=""/>
            <h6 className="text-center text-success fw-bold" style={{fontSize: "x-large"}}>TWIM Rectangular Container & Dip Pot Series</h6>           
        </div>
        <hr className="divider" />
        <p>Our thin-wall containers are lightweight yet durable, perfect for storing takeout, salads, deli items, and more. These containers offer leak-proof designs and are a cost-effective solution for packaging food safely.</p>
        <div className="container-fluid">      
            <div className="d-flex justify-content-evenly flex-sm-row flex-column" >
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/PP-1000.png")} className="card-img-top" alt="" style={{width:"30%"}}/>
                    </div>
                    <div className="card-body">  
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>RC-1000</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>1000ml Rectangular Container with Separate Lid</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>50 PCS x 5 Sleeves (250 PCS) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Polypropylene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Colors</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Transparent | Black | White</td>
                            </tr>
                            </tbody>
                        </table>                
                    </div>
                </div>
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/PP-500.png")} className="card-img-top" alt="..." style={{width:"30%"}}/>
                    </div>
                    <div className="card-body">  
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>RC-750</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>750ml Rectangular Container with Separate Lid</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>50 PCS x 5 Sleeves (250 PCS) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Polypropylene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Colors</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Transparent | Black | White</td>
                            </tr>
                            </tbody>
                        </table>                
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-evenly flex-sm-row flex-column" >
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/PP-500.png")} className="card-img-top" alt="..." style={{width:"30%"}}/>
                    </div>
                    <div className="card-body">  
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>RC-500</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>500ml Rectangular Container with Separate Lid</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>50 PCS x 5 Sleeves (250 PCS) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Polypropylene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Colors</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Transparent | Black | White</td>
                            </tr>
                            </tbody>
                        </table>                
                    </div>
                </div>             
            </div>
            <div className="d-flex justify-content-evenly flex-sm-row flex-column" >
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/PP-01.png")} className="card-img-top" alt="..." style={{width:"30%"}}/>
                    </div>
                    <div className="card-body">  
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>DP-01</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>1 Oz Dip Pot with Hinged LLid</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>50 PCS x 60 Sleeves (3000 PCS) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Polypropylene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Colors</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Transparent</td>
                            </tr>
                            </tbody>
                        </table>                
                    </div>
                </div>
                <div className="card shadow-lg mb-5 bg-white rounded ">
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={require("./images/PP-02.png")} className="card-img-top" alt="..." style={{width:"30%"}}/>
                    </div>
                    <div className="card-body">  
                        <table className="table text-success" style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Article Code</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>DP-02</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: "right", fontWeight:"bold"}}>Article Description</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>2 Oz Dip Pot with Hinged Lid</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Packing</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>50 PCS x 60 Sleeves (3000 PCS) per Carton</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Material</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Polypropylene</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "right", fontWeight:"bold"}}>Available Colors</td>
                                <td className="text-secondary" style={{paddingLeft: "5px"}}>Transparent</td>
                            </tr>
                            </tbody>
                        </table>                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br></br>
    </>
  )
}
