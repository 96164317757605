import React from 'react'
import logo from './images/AHR-Logo-50-x-50.png'
import { Link } from 'react-router-dom'
import './css/menu.css'

export const Header = () => {
    
  

  const clicked = (e) =>{

    const links = document.getElementsByClassName('nav-link');
    
    //console.log(links);
    for(let link of links){
      
      link.classList.remove('active');
    }

   if(e.target.innerText==="EPS Foam Cup" || e.target.innerText==="PP TWIM Containers"){
      e.target.parentNode.parentNode.parentNode.firstChild.classList.add('active');
    }
    else{
      e.target.classList.add('active');
    }
  }

  return (
    <>
        <div className="navbar navbar-expand-sm navbar-dark text-light bg-success">
          <div className="col-2">
            <img className= "mx-3" src={logo} alt="" loading="lazy"/>
          </div>
          <div className="col-7 align-items-center justify-content-start">
            <div className="text-light fw-bold logos">
              <div className="logos-slide">
                <span style={{fontSize: "larger", overflow: "hidden"}}>Premium Food Packaging Products | Best Quality Foam Cups | Superior Quality Dip Pots </span>
              </div>
              
            </div>
          </div>
          <div className="col d-flex justify-content-end">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="col-3">
            <div className="container collapse navbar-collapse justify-content-lg-center" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fw-bold">
                <li className="nav-item ni">
                  <Link className="nav-link active" onClick={(e)=>clicked(e)} aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item ni">
                  <Link className="nav-link" onClick={(e)=>clicked(e)}  to="/about">About</Link>
                </li>
                <li className="nav-item ni">
                  <Link className="nav-link disabled" to="/news">News</Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Products
                  </Link>
                  <ul className="dropdown-menu bg-success">
                    <li><Link className="dropdown-item text-light" onClick={(e)=>clicked(e)}  to="/product_eps">EPS Foam Cup</Link></li>
                    <li><Link className="dropdown-item text-light" onClick={(e)=>clicked(e)}  to="/product_pp">PP TWIM Containers</Link></li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li><Link className="dropdown-item disabled" to="/product_hprs">Himalayan Pink Salt</Link></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link disabled" to="/Linkpps">Apps</Link>
                </li>
                <li className="nav-item ni">
                  <Link className="nav-link" onClick={(e)=>clicked(e)}  to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </>
  )
}
