import React from 'react'
import './css/img-slider.css'
import correctsvg from './icons/icons8-correct-25.png'
import {Link} from 'react-router-dom'

export const Home = () => {
  
  return (
    <>

    {/*Cover Photos Slider Starts Here*/ }
    <div id="slider" >
      <img src={require('./images/coverphoto_1.png')} loading="lazy" alt="" />
      <img src={require('./images/coverphoto_2.png')} loading="lazy" alt="" />  
    </div>
    {/*Cover Photos Slider Ends Here*/ }

    <div className="container d-flex align-items-center justify-content-center">
        <div className="text-secondary">
          <hr className="divider" />
          <h6 className="text-center fw-bold text-success" style={{fontSize: "x-large"}}>Your Trusted Supplier of Foam Cups and Thinwall Containers</h6>
          <hr className="divider" />
          <span className="text-center fw-bold" style={{fontSize: "large"}}>Designed for durability, convenience, and sustainability in the Foodservice
            Industry.
          </span>
          <br /><br />
          <p style={{fontSize: "medium"}} >We specialize in manufacturing high-quality foam cups and thinwall containers that keep
            your products <b>safe</b>, <b>fresh</b>, and <b>ready</b> for use. Whether you’re in the <i><u>beverage</u>, <u>takeout</u>, or <u>catering industry</u></i>, <b>our
            packaging solutions</b> offer a perfect balance of <b>strength</b>, <b>insulation</b>, and <b>convenience</b>. Our products are engineered
            to meet the <u>specific needs of foodservice providers</u>, ensuring that your customers enjoy a <b><i>"superior experience"</i></b> with
            every product.
          </p>
          <br />
          <h6 className="text-center text-success fw-bold" style={{fontSize: "large"}}>Explore our Premium Foam Cups & Thinwall Containers</h6>
          <hr className="divider" />
            <div className="container-fluid bg-transparent my-4 p-3" style={{position: "relative"}}>
              <div className="row row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-sm-3 g-3">
                <div className="col">
                  <div className="card h-100 align-items-center shadow-sm bg-white"> 
                    <img src={require('./images/A06-R.png')} className="card-img-top" alt="" style={{width:"200px", height: "auto"}} />
                    <div className="card-body">
                      <div className="container d-flex justify-content-center align-items-center mb-3"> <span className="badge rounded-pill fw-bold fs-5 bg-success">Foam Cups</span> </div>
                      <div className="card-text text-secondary text-center">
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}}/><h6 style={{paddingLeft: "5px"}}>Environment Friendly (CFC Free)</h6>
                        </div>
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}}/><h6 style={{paddingLeft: "5px"}}>Fullly Compliant with US FDA & EU Regulations</h6>
                        </div>
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}}/><h6 style={{paddingLeft: "5px"}}>Maintain Beverage's Temperature Longer</h6>
                        </div>
                        <div className="my-4">
                          <Link to="/product_eps" className="btn btn-warning text-center">Show Related Products</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 shadow-sm align-items-center bg-white"> <img src={require('./images/A06-I.png')} className="card-img-top" alt="" style={{width:"200px", height: "auto"}} />
                    <div className="card-body">
                      <div className="container d-flex justify-content-center align-items-center mb-3"> 
                        <span className="badge rounded-pill fw-bold fs-5 bg-success">Dip Pot Series</span>
                      </div>
                      <div className="card-text text-secondary text-center">
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}} />
                          <h6 style={{paddingLeft: "5px"}}>BPA Free</h6>
                        </div>
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}} />
                          <h6 style={{paddingLeft: "5px"}}>Food Grade</h6>
                        </div>
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}} />
                          <h6 style={{paddingLeft: "5px"}}>ECO Friendly & Recyclable</h6>
                        </div>
                        <div className="my-4">
                          <Link to="/product_pp" className="btn btn-warning text-dark">Show Related Products</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 align-items-center shadow-sm bg-white"> <img src={require('./images/PP-500.png')} className="card-img-top" alt="" style={{width: "200px", height: "auto"}} />
                    <div className="card-body">
                      <div className="container d-flex justify-content-center align-items-center mb-3"> <span
                          className="badge rounded-pill fw-bold fs-5 bg-success">Rectangular Container Series</span> </div>
                      <div className="card-text text-secondary text-center">
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}} />
                          <h6 style={{paddingLeft: "5px"}}>BPA Free</h6>
                        </div>
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}} />
                          <h6 style={{paddingLeft: "5px"}}>Food Grade</h6>
                        </div>
                        <div className="d-flex">
                          <img src={correctsvg} alt="" style={{width:"20px", height:"20px"}} />
                          <h6 style={{paddingLeft: "5px"}}>Freezer & Microwave Proof</h6>
                        </div>
                        <div className="my-4">
                          <Link to="/product_pp" className="btn btn-warning text-dark">Show Related Products</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          <h6 className="text-center text-success fw-bold" style={{fontSize: "large"}}>Why Our Foam Cups & Thinwall Containers Stand Out?</h6>
          <hr className="divider" />
          <div className="d-flex">
            <h6 style={{paddingLeft: "5px"}}><b><u>Durability & Quality:</u></b><i> Engineered for strength, our products maintain their integrity, even in high-heat or cold conditions.</i></h6>
          </div>
          <div className="d-flex">
            <h6 style={{paddingLeft: "5px"}}><b><u>Excellent Insulation:</u></b><i> Our foam cups keep beverages hot and food fresh longer, enhancing the customer experience.</i></h6>
          </div>
          <div className="d-flex">
            <h6 style={{paddingLeft: "5px"}}><b><u>Leak-Proof Design:</u></b><i> Our thin-wall containers are designed to prevent leaks, ensuring your customers receive their orders safely and without spills.</i></h6>
          </div>
          <br/><br/>
        </div>
      </div>


    </>
  )
}
