import React from 'react'
import './css/fixed.css'

export const Fixed = () => {
  
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
      // Get the button
      let mybutton = document.getElementById("btnScrollTop");
        
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        //console.log("error if", mybutton);
        mybutton.style.display = "block";
      } else {
        //console.log("error else", mybutton);
        mybutton.style.display = "none";
      }
    }
    
    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  
    return (
    <>
    <div className="wbcontainer">
        <a className="wb-btn" href="https://wa.me/924235297336">
          <img src={require('./icons/icons8-whatsapp-business-50.png')} alt="" style={{width:"50px", height: "50px"}} />
        </a>
        <span>Get Quote</span>
      </div>
      <button onClick={()=>{topFunction()}} id="btnScrollTop" title="Go to top">Top</button>
    </>
  )
}
