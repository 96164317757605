import {React, useState} from 'react'
import  axios  from 'axios';
import './css/contact.css';

export const Contact = () => {

    const [inputs, setInputs] = useState({});

    const hChange = (e) =>{

        const name = e.target.name;
        const value = e.target.value;

        setInputs(values => ({...values, [name]:value}));
    }

    const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
    const appendAlert = (message, type) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = [
            `<div class="alert alert-${type} alert-dismissible" role="alert">`,
            `   <div>${message}</div>`,
            '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
            '</div>'
        ].join('')

        alertPlaceholder.append(wrapper)
    }

    const sendMail = (e) => {
        
        e.preventDefault();
        
        let form = e.currentTarget;
        form.style.display="none";
        let loader = document.getElementById('loader');
        loader.style.display = "flex";
        loader.style.alignItems = "center";
        loader.style.justifyContent = "center";
        loader.style.width="100%";
        loader.style.height="100%";
        //loader.style.height="250px";
        //console.log(inputs);

        if(form.checkValidity()){
            
            axios({
                method: 'post',
                url : 'https://website.ahr-industries.com/api/smtp/send.php', 
                data : inputs})
            .then( function (response){
                //console.log("status",response.data.message);
                response.data.status===1?appendAlert(response.data.message, 'success'):appendAlert(response.data.message, 'danger');
                form.reset();
                //Deleting Inputs Object
                for (let input in inputs){
                    delete inputs[input];
                }
                loader.style.display = "none";
                form.style.display = "block";
            })
            .catch(function (error){
                //console.log("Error",error);
                appendAlert(error, 'danger');
                loader.style.display = "none";
                form.style.display = "block";
            });

            
        }
        
    }



  return (
    <>
    <div className="container">
        <hr className="divider" />
        <h1 className="text-center text-success fw-bold" style={{fontSize: "xx-large"}}>Ready to Enhance Your Packaging?</h1>
        <hr className="divider" />
        <p className="text-secondary">Let us help you find the right foam cups, thinwall containers and dip pots to meet your needs. Whether you’re looking for bulk orders or customized products, we’re here to assist you every step of the way.</p>
        <br />
        <div className="d-flex justify-content-center flex-sm-row flex-column">
            <div className="container text-secondary">
                <hr className="divider"/>
                <h3 className="text-success fw-bold">Write to Us <img src={require("./icons/icons8-write-30.png")} alt=""/></h3>
                <hr className="divider"/>
                <div id="loader" style={{display:"none"}}>
                    <span className="loader"></span>
                </div>
                <form className="needs-validation" onSubmit={(e)=>{ sendMail(e)}}>
                    <div id="liveAlertPlaceholder"></div>
                    <div className="mb-3 mt-3">
                        <label htmlFor="fullName" className="form-label">Your Full Name</label>
                        <input type="text" className="form-control" id="fullName" onInput={(e)=>hChange(e)} placeholder="Enter Your Full Name" name="fullname" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="emailAddress" className="form-label">Your Email Address</label>
                        <input type="email" className="form-control" id="emailAddress" onInput={(e)=>hChange(e)} placeholder="example@example.com" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" name="email" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="telNumber" className="form-label">Your Contact Number (Country Code-Area/Mobile Code-Number)</label>
                        <input type="tel" className="form-control" id="telNumber" onInput={(e)=>hChange(e)} placeholder="923210000000" pattern="[0-9]{10,}" name="number" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Your Message</label>
                        <textarea className="form-control" id="message" onInput={(e)=>hChange(e)} placeholder="write your message" name="message" required rows="3" minLength="10" maxLength="250"></textarea>
                    </div>
                    
                    <button type="submit" className="btn btn-success" >Submit</button>
                    </form>
            </div>
            <div className="container text-secondary">
                <hr className="divider"/>
                <h3 className="text-success fw-bold">Our Location <img src={require("./icons/icons8-location-30.png")} alt=""/></h3>
                <hr className="divider"/>
                <div className="container mt-2">
                    <h4 className="text-success fw-bold">AHR Industries (Private) Limited</h4>
                    <span><i>Plot No. 135, Sundar Industrial Estate, Raiwind Road, Lahore, Pakistan.</i></span>
                    <br />
                    <span><a href="tel:+924235297336"><img src={require("./icons/icons8-phone-25.png")} alt=""/></a> | <a href="https://wa.me/924235297336"><img src={require("./icons/icons8-whatsapp-business-25.png")} alt=""/></a> : <b>+92-42-35297336</b></span>
                    
                </div>
                <br />
                <iframe title="1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4079.7781554102826!2d74.17675347612023!3d31.291754458400618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3918548e823f5de7%3A0x919aedde8b71bac6!2sAHR%20Industries%20(Private)%20Limited!5e1!3m2!1sen!2s!4v1742659557695!5m2!1sen!2s" width="400" height="300" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                
            </div>
        </div>
    </div>
    <br/><br/><br/><br/>
    </>
  )
}
