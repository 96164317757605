import './App.css';
import { Header } from './components/Header'
import { Home } from './components/Home'
import { About } from './components/About'
import { Product_EPS } from './components/Product_EPS'
import { Product_PP } from './components/Product_PP'
import { Contact } from './components/Contact'
import { Fixed } from './components/Fixed'
import { Footer } from './components/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />  
          <Route exact path="/about" element={<About />}/>
          <Route exact path="/product_eps" element={<Product_EPS />}/>
          <Route exact path="/product_pp" element={<Product_PP />}/>
          <Route exact path="/contact" element={<Contact />}/>
        </Routes>
        <Fixed />
        <Footer />
    </Router>
    </>
    );
}

export default App;
